/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable object-curly-newline */
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

// import colors from '../../utils/colors';

import { useEffect, useState } from 'react';
import Image from '../../components/Image';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5rem;
  width: 70vw;
  ${({ index }) => index === 0 && 'margin: 100px 0 20px;'};
  min-height: 80vh;
  @media screen and (max-width: 1200px) {
    row-gap: 3rem;
    ${({ index }) => index === 0 && 'margin: 70px 0 20px;'};
    width: 100%;
  }
  @media screen and (max-width: 520px) {
    row-gap: 1rem;
    min-height: 60vh;
  }
`;

const Title = styled.h1`
  font-size: var(--h2-font-size);

  @media screen and (max-width: 500px) {
    margin: 1rem 0;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  column-gap: 5rem;
  @media screen and (max-width: 1200px) {
    column-gap: 2rem;
  }
  @media screen and (max-width: 500px) {
    column-gap: 1rem;
  }
`;

const ImageWrapper = styled.div`
  width: 30rem;
  height: 30rem;
  overflow: hidden;
  @media screen and (max-width: 1200px) {
    width: 30vw;
    height: 30vw;
  }
`;

const LocalisationImageWrapper = styled(ImageWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
  transition: all 120ms ease-in-out;
  ${({ localisation }) =>
    localisation &&
    `
    :hover {
      transform: scale(1.01);
      opacity: 0.8;
  };`}
`;

const Separation = styled.div`
  display: ${({ showSeparation }) => (showSeparation ? 'block' : 'none')};
  height: 2px;
  width: 70vw;
  margin: auto;
  background-color: var(--text-color-dark);
  /* transition: background-color 180ms linear; */ /*Only for Themed animation (not implemented yep) */
`;

const Shop = ({
  title,
  text,
  vitrine,
  localisation,
  mapLink,
  showSeparation,
  index,
}) => {
  const [localisationImage, setLocalisationImage] = useState();
  const [vitrineImage, setVitrineImage] = useState();

  useEffect(() => {
    if ((vitrine, localisation)) {
      setLocalisationImage(Object.values(localisation)[0]);
      setVitrineImage(Object.values(vitrine)[0]);
    }
  }, [vitrine, localisation]);

  return (
    <Container index={index}>
      <Title>{title}</Title>
      <ReactMarkdown remarkPlugins={[[gfm, { singleTilde: false }]]}>
        {text}
      </ReactMarkdown>
      <ImageContainer>
        <ImageWrapper>
          {vitrineImage && <StyledImage src={vitrineImage} />}
        </ImageWrapper>
        <LocalisationImageWrapper>
          <a href={mapLink} target="_blank" rel="noreferrer">
            {localisationImage && (
              <StyledImage localisation src={localisationImage} />
            )}
          </a>
        </LocalisationImageWrapper>
      </ImageContainer>
      <Separation showSeparation={showSeparation} />
    </Container>
  );
};

Shop.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  vitrine: PropTypes.string.isRequired,
  localisation: PropTypes.string.isRequired,
  mapLink: PropTypes.string.isRequired,
  showSeparation: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
};

export default Shop;
