import PropTypes from 'prop-types';
import styled from 'styled-components';
import instagramLogo from '../../assets/icons/Instagram_Glyph_Gradient_RGB.png';

const Container = styled.div`
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 3rem;
`;

const InstagramLogo = styled.img`
  width: 3rem;
  height: auto;
`;

const Contact = ({ email, instagram }) => (
  <Container>
    <a href={`mailto:${email}`}>{email}</a>
    <a href={instagram} target="_blank" rel="noreferrer">
      <InstagramLogo src={instagramLogo} alt="Instagram" />
    </a>
  </Container>
);

Contact.propTypes = {
  email: PropTypes.string.isRequired,
  instagram: PropTypes.string.isRequired,
};

export default Contact;
