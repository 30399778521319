import initialFormState from './content.initial';

const contentReducer = (state, action) => {
  switch (action.type) {
    case 'EDIT': {
      return { ...state, [action.field]: action.payload };
    }
    case 'RESET': {
      return initialFormState;
    }
    default: {
      return state;
    }
  }
};

export default contentReducer;
