import PropTypes from 'prop-types';
import { createContext, useEffect, useState } from 'react';

export const WindowWidthContext = createContext();

const WindowWidthProvider = ({ children }) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        setWindowSize(window.innerWidth);
      }, 100);
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [windowSize]);

  return (
    <WindowWidthContext.Provider value={windowSize}>
      {children}
    </WindowWidthContext.Provider>
  );
};

WindowWidthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WindowWidthProvider;
