import { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import contentReducer from '../reducers/content.reducer';
import initialFormState from '../reducers/content.initial';

export const ContentEditContext = createContext();
const ContentEditProvider = ({ children }) => {
  const [state, dispatch] = useReducer(contentReducer, initialFormState);

  const handleChange = (e) => {
    dispatch({
      type: 'EDIT',
      field: e.target.name,
      payload: e.target.value,
    });
  };

  const handleAddValue = (name, value) => {
    dispatch({
      type: 'EDIT',
      field: name,
      payload: value,
    });
  };
  return (
    <ContentEditContext.Provider
      value={{
        state,
        handleChange,
        handleAddValue,
        dispatch,
      }}
    >
      {children}
    </ContentEditContext.Provider>
  );
};

ContentEditProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentEditProvider;
