/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

// Import modules
import { useContext, useEffect, useRef, useState } from 'react';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

// Import medias
import { Button } from '@mui/material';
import LogoOrange from '../../assets/images/yperlab_logo_ff4612.png';

// Import contexts
import { ScrollPositionContext } from '../../contexts/ScrollPositionContext';
import { WindowWidthContext } from '../../contexts/WindowWidthContext';

// Import styles
import './Navbar.scss';
import './Menu.scss';

// Import components
import Burger from '../Burger/Burger';

const NavBar = styled.div`
  ${({ dropShadow }) =>
    dropShadow && 'filter: drop-shadow(2px 4px 6px black);'};
  transition: all ease 100ms;
`;

const Navbar = () => {
  const { scrollDown } = useContext(ScrollPositionContext);
  const windowSize = useContext(WindowWidthContext);
  const menuList = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [visibleBackArrow, setVisibleBackArrow] = useState(false);
  const [dropShadow, setDropShadow] = useState(false);

  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const pathLength = pathname.split('/').length;

    if (pathLength > 3) {
      setVisibleBackArrow(true);
    } else {
      setVisibleBackArrow(false);
    }
  }, [pathname]);

  useEffect(() => {
    if (windowSize < 900 && !menuOpen) {
      setDropShadow(true);
    } else if (windowSize >= 900 && scrollDown) {
      setDropShadow(true);
    } else {
      setDropShadow(false);
    }
  }, [windowSize, menuOpen, scrollDown]);

  const handleOpenMenu = () => {
    if (windowSize < 900) {
      if (!menuOpen) {
        menuList.current.className = 'menu__list--open';
      } else {
        menuList.current.className = 'menu__list';
      }
      setMenuOpen(!menuOpen);
    }
  };

  return (
    <>
      <NavBar
        dropShadow={dropShadow}
        className={`navbar${
          scrollDown && windowSize < 900 && !menuOpen ? '--hidden' : ''
        }`}
      >
        <Button
          className={`back-arrow${visibleBackArrow ? '--visible' : ''}`}
          onClick={() => history.goBack()}
        >
          <ArrowBackRoundedIcon
            className={`back-arrow__icon${visibleBackArrow ? '--visible' : ''}`}
            fontSize="large"
          />
        </Button>
        <Burger menuOpen={menuOpen} handleOpenMenu={handleOpenMenu} />
        <NavLink
          to="/"
          onClick={handleOpenMenu}
          className="menu__link"
          activeClassName="menu__link--active"
        >
          <img src={LogoOrange} alt="logo orange" className="navbar__logo" />
        </NavLink>
      </NavBar>
      <div
        className={
          windowSize < 900
            ? 'menu__list'
            : `menu__list${scrollDown ? '' : '--open'}`
        }
        ref={menuList}
      >
        <NavLink
          to="/nav/news"
          onClick={handleOpenMenu}
          className="menu__link"
          activeClassName="menu__link--active"
        >
          NEWS
        </NavLink>
        <NavLink
          to="/nav/creations"
          onClick={handleOpenMenu}
          className="menu__link"
          activeClassName="menu__link--active"
        >
          CREATIONS
        </NavLink>
        <NavLink
          to="/nav/stylisme"
          onClick={handleOpenMenu}
          className="menu__link"
          activeClassName="menu__link--active"
        >
          STYLISME
        </NavLink>
        <NavLink
          to="/nav/press-media"
          onClick={handleOpenMenu}
          className="menu__link"
          activeClassName="menu__link--active"
        >
          PRESS/MEDIA
        </NavLink>
        <NavLink
          to="/nav/shop"
          onClick={handleOpenMenu}
          className="menu__link"
          activeClassName="menu__link--active"
        >
          SHOPS
        </NavLink>
        <NavLink
          to="/nav/about-us"
          onClick={handleOpenMenu}
          className="menu__link"
          activeClassName="menu__link--active"
        >
          BIO
        </NavLink>
        <NavLink
          to="/nav/contact"
          onClick={handleOpenMenu}
          activeClassName="menu__link--active"
        >
          CONTACT
        </NavLink>
      </div>
    </>
  );
};

export default Navbar;
