import convertDate from '../tools/convertDate';

const initialFormState = {
  id: null,
  title: '',
  subTitle: '',
  shortText: '',
  longText: '',
  displayDate: convertDate(Date.now()),
  coverImage: '',
  images: [],
};
export default initialFormState;
