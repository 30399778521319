import firebase from 'firebase/app';
import 'firebase/auth'; // for authentication
import 'firebase/storage'; // for storage
import 'firebase/database'; // for realtime database

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: 'yperlab-4a4ab.firebaseapp.com',
  databaseURL:
    'https://yperlab-4a4ab-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'yperlab-4a4ab',
  storageBucket: 'yperlab-4a4ab.appspot.com',
  messagingSenderId: '303222009173',
  appId: '1:303222009173:web:716f6b5be57313f12413dc',
  measurementId: 'G-XYN63Y03SC',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const fireAuth = firebase.auth();
export default firebase;
