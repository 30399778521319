import { Link } from 'react-router-dom';
import './AdminHome.scss';
import { useEffect, useContext, useState } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { List, ListItem, Paper } from '@material-ui/core';
import { ListItemButton } from '@mui/material';
import styled from 'styled-components';

import firebase, { fireAuth } from '../../utils/firebase.config';
import { AuthContext } from '../../contexts/AuthContext';

const StyledPaper = styled(Paper)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  background-color: var(--first-color-blue);
  color: var(--first-color-orange);
`;

const StyledList = styled(List)`
  text-align: center;
`;

const StyledLink = styled(Link)`
  width: 100%;
  text-align: center;
`;

const AdminHome = () => {
  const { isSignedIn, updateSignedIn } = useContext(AuthContext);

  const [user, setUser] = useState(null);

  const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        disableSignUp: { status: true },
      },
    ],
    callbacks: {
      signInSuccess: () => updateSignedIn(true),
    },
  };

  useEffect(() => {
    fireAuth.onAuthStateChanged((connectedUser) => setUser(connectedUser));
  }, []);

  return (
    <>
      {isSignedIn && user ? (
        <div className="admin">
          <div className="admin__bg" />
          <StyledPaper elevation={4}>
            <h1>Page d&apos;administation</h1>
            <StyledList>
              <ListItem divider disableGutters>
                <ListItemButton>
                  <StyledLink to="/admin/news">NEWS</StyledLink>
                </ListItemButton>
              </ListItem>
              <ListItem divider disableGutters>
                <ListItemButton>
                  <StyledLink to="/admin/creations">CREATIONS</StyledLink>
                </ListItemButton>
              </ListItem>
              <ListItem divider disableGutters>
                <ListItemButton>
                  <StyledLink to="/admin/stylisme">STYLISMES</StyledLink>
                </ListItemButton>
              </ListItem>
              <ListItem divider disableGutters>
                <ListItemButton>
                  <StyledLink to="/admin/press-media">PRESS / MEDIA</StyledLink>
                </ListItemButton>
              </ListItem>
              <ListItem divider disableGutters>
                <ListItemButton>
                  <StyledLink to="/admin/shop">SHOP</StyledLink>
                </ListItemButton>
              </ListItem>
              <ListItem disableGutters>
                <ListItemButton>
                  <StyledLink to="/admin/about-us">ABOUT-US</StyledLink>
                </ListItemButton>
              </ListItem>
            </StyledList>
          </StyledPaper>
        </div>
      ) : (
        <div>
          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
          />
        </div>
      )}
    </>
  );
};

export default AdminHome;
