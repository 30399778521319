import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';

import ControlPointIcon from '@mui/icons-material/ControlPoint';
import styled from 'styled-components';

const IconLink = styled(Link)`
  display: block;
  width: fit-content;
  margin: auto;

  .MuiSvgIcon-root {
    transform: rotate(0);
    transition: all 400ms ease-in-out;
  }
  &:hover {
    .MuiSvgIcon-root {
      transform: rotate(90deg) scale(1.1);
      filter: opacity(0.9);
    }
  }
`;

const SeeMoreButton = ({ link }) => (
  <Tooltip title="En voir plus" arrow>
    <IconLink to={link} className="plus-icon">
      <ControlPointIcon
        fontSize="large"
        sx={{ color: 'var(--first-color-orange)' }}
      />
    </IconLink>
  </Tooltip>
);

SeeMoreButton.propTypes = {
  link: PropTypes.string.isRequired,
};

export default SeeMoreButton;
