// Import modules
import PropTypes from 'prop-types';
import { createContext, useEffect, useState } from 'react';

export const ScrollPositionContext = createContext();

const ScrollPositionProvider = ({ children }) => {
  const [scrollPosition, setScrollPosition] = useState(window.scrollY);
  const [scrollDown, setScrollDown] = useState(false);

  useEffect(() => {
    const prevPosition = scrollPosition;
    const handleScroll = () => {
      if (prevPosition > window.scrollY || window.scrollY <= 80) {
        setScrollDown(false);
      } else setScrollDown(true);
      setScrollPosition(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollPosition]);

  return (
    <ScrollPositionContext.Provider value={{ scrollPosition, scrollDown }}>
      {children}
    </ScrollPositionContext.Provider>
  );
};

ScrollPositionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ScrollPositionProvider;
