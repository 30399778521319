import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import MDEditor from '@uiw/react-md-editor';
import { Button } from '@material-ui/core';

import styled from 'styled-components';

import firebase from '../../utils/firebase.config';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80vw;
`;

const SectionIntroText = ({ contentType }) => {
  const [introText, setIntroText] = useState('');

  useEffect(() => {
    firebase
      .database()
      .ref()
      .child(contentType)
      .child('intro-text')
      .on('value', (content) => {
        const prevIntroText = content.val();
        setIntroText(prevIntroText);
      });
  }, []);

  const handleSubmit = () => {
    firebase
      .database()
      .ref()
      .child(contentType)
      .update({ 'intro-text': introText });
  };

  return (
    <StyledContainer>
      <MDEditor
        textareaProps={{ placeholder: 'Saisir le texte ici' }}
        value={introText}
        onChange={setIntroText}
        height={350}
      />
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Valider
      </Button>
    </StyledContainer>
  );
};

SectionIntroText.propTypes = {
  contentType: PropTypes.string.isRequired,
};

export default SectionIntroText;
