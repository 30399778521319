/* eslint-disable newline-per-chained-call */
/* eslint-disable operator-linebreak */
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TextField, Button } from '@material-ui/core';
import MDEditor from '@uiw/react-md-editor';

import imageCompression from 'browser-image-compression';

import { useAlert } from 'react-alert';
import { useHistory, useParams } from 'react-router-dom';
import firebase from '../../../utils/firebase.config';

const StyledForm = styled.form`
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

const ImageUploadWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ImageUpload = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageInput = styled.input`
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  width: 10rem;
  height: 10rem;
  overflow: hidden;
`;

const ImagePreview = styled.img`
  width: 100%;
  height: auto;
`;

const CreateShop = () => {
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [image, setImage] = useState([]);
  const [localisation, setLocalisation] = useState([]);
  const [localisationPreview, setLocalisationPreview] = useState();
  const [imagePreview, setImagePreview] = useState();
  const [mapLink, setMapLink] = useState('');
  const [currentImageId, setCurrentImageId] = useState();
  const [currentLocalisationId, setCurrentLocalisationId] = useState();

  const alert = useAlert();
  const history = useHistory();

  const { shopId } = useParams();

  const isUpdate = Boolean(shopId);

  useEffect(() => {
    if (shopId) {
      firebase
        .database()
        .ref()
        .child('shop')
        .child(shopId)
        .on('value', (contentSnapshot) => {
          const content = contentSnapshot.val();
          const snapImage = Object.values(content.vitrine)[0];
          const snapLocalisation = Object.values(content.localisation)[0];
          const snapImageId = Object.keys(content.localisation)[0];
          const snapLocalisationId = Object.keys(content.localisation)[0];

          setTitle(content.title);
          setText(content.text);
          setImage(snapImage);
          setImagePreview(snapImage);
          setLocalisation(snapLocalisation);
          setLocalisationPreview(snapLocalisation);
          setMapLink(content.mapLink);
          setCurrentImageId(snapImageId);
          setCurrentLocalisationId(snapLocalisationId);
        });
    }
  }, []);

  const isButtonDisable =
    title &&
    text &&
    image &&
    localisation &&
    mapLink &&
    (!title.length ||
      !text.length ||
      !image.length ||
      !localisation.length ||
      !mapLink.length);

  const handleAddImage = (e) => {
    const { files } = e.target;
    setImage(files);
    setImagePreview(URL.createObjectURL(files[0]));
  };

  const handleAddLocalisation = (e) => {
    const { files } = e.target;
    setLocalisation(files);
    setLocalisationPreview(URL.createObjectURL(files[0]));
  };

  const handleUploadImage = async (targetShopId, source) => {
    const timeStamp = Date.now();
    if (source === 'vitrine' && !image.length) {
      alert.show('Merci de selectionner une image de vitrine');
    }
    if (source === 'localisation' && !localisation.length) {
      alert.show('Merci de selectionner une image de localisation');
    } else {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const imageSource = source === 'vitrine' ? image[0] : localisation[0];
        if (typeof imageSource !== 'string') {
          const compressedFile = await imageCompression(imageSource, options);
          firebase
            .storage()
            .ref()
            .child(`shop-images/${timeStamp}-${imageSource.name}`)
            .put(compressedFile)
            .on(
              'state_changed',
              () => {},
              (err) => {
                // catches the errors
                alert.show(err);
              },
              () => {
                firebase
                  .storage()
                  .ref('shop-images')
                  .child(`${timeStamp}-${imageSource.name}`)
                  .getDownloadURL()
                  .then((fireBaseUrl) => {
                    if (isUpdate) {
                      firebase
                        .database()
                        .ref()
                        .child('shop')
                        .child(targetShopId)
                        .child(
                          source === 'vitrine' ? 'vitrine' : 'localisation'
                        )
                        .update(
                          source === 'vitrine'
                            ? { [currentImageId]: fireBaseUrl }
                            : { [currentLocalisationId]: fireBaseUrl }
                        );
                    } else {
                      firebase
                        .database()
                        .ref()
                        .child('shop')
                        .child(targetShopId)
                        .child(
                          source === 'vitrine' ? 'vitrine' : 'localisation'
                        )
                        .push(fireBaseUrl);
                    }
                  });
              }
            );
        }
      } catch (error) {
        alert.error('Something went wrong');
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const currentTimestamp = new Date().toUTCString();

    if (isUpdate) {
      firebase.database().ref().child('shop').child(shopId).update({
        title,
        text,
        mapLink,
        updatedAt: currentTimestamp,
      });
      if (!image.length || !localisation.length) {
        alert.show('Merci de selectionner un fichier');
      } else {
        handleUploadImage(shopId, 'vitrine');
        handleUploadImage(shopId, 'localisation');
      }
      alert.show('Création réussie');
      history.push('/admin/shop');
    } else {
      const createdShop = firebase.database().ref().child('shop').push({
        title,
        text,
        mapLink,
        activated: false,
        createdAt: currentTimestamp,
        updatedAt: currentTimestamp,
      });
      if (!image.length || !localisation.length) {
        alert.show('Merci de selectionner un fichier');
      } else {
        handleUploadImage(createdShop.key, 'vitrine');
        handleUploadImage(createdShop.key, 'localisation');
      }
      alert.show('Création réussie');
      history.push('/admin/shop');
    }
  };

  return (
    <>
      <h1>Création de shop</h1>
      <StyledForm onSubmit={handleSubmit}>
        <TextField
          label="Titre"
          variant="filled"
          name="title"
          id="Titre"
          placeholder="Titre"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
        <MDEditor
          style={{ width: '80%' }}
          textareaProps={{ placeholder: 'Please enter Markdown text' }}
          value={text}
          onChange={setText}
        />
        <ImageUploadWrapper>
          <ImageUpload>
            <ImageWrapper>
              <ImagePreview src={imagePreview} alt="vitrine" />
            </ImageWrapper>
            <ImageInput
              type="file"
              name="image"
              accept="image/*"
              onChange={handleAddImage}
            />
          </ImageUpload>
          <ImageUpload>
            <ImageWrapper>
              <ImagePreview src={localisationPreview} alt="localisation" />
            </ImageWrapper>
            <ImageInput
              type="file"
              name="image"
              accept="image/*"
              onChange={handleAddLocalisation}
            />
          </ImageUpload>
        </ImageUploadWrapper>
        <TextField
          label="Lien google map"
          variant="filled"
          name="mapLink"
          id="Lien google map"
          placeholder="Lien google map"
          value={mapLink}
          onChange={(e) => setMapLink(e.target.value)}
          required
        />
        <Button type="submit" variant="outlined" disabled={isButtonDisable}>
          Valider
        </Button>
      </StyledForm>
    </>
  );
};

export default CreateShop;
