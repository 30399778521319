/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { CircularProgress } from '@material-ui/core';
import firebase from '../../utils/firebase.config';

import News from './News';
import Creation from './Creation';
import Stylisme from './Stylisme';
import PressMedia from './PressMedia';
import Shop from './Shop';

import SectionContainer from '../../components/SectionContainer';

import './News.scss';
import './Creation.scss';
import './Stylisme.scss';
import AboutUs from './AboutUs';
import Contact from './Contact';

const Container = styled.div`
  display: flex;
  ${({ $type }) => {
    if ($type === 'shop' || $type === 'about-us' || $type === 'contact') {
      return `
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;
    }
    if ($type === 'creations' || $type === 'press-media') {
      return `
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 1rem;
    @media screen and (max-width: 520px) {
      .MuiTypography-root {
        max-width: 140px;
        line-height: inherit;
        font-size: small;
      }
    }
    `;
    }
    return '';
  }}
`;

const MainCategories = () => {
  const [content, setContent] = useState();
  const [introText, setIntroText] = useState('');

  const { contentType } = useParams();

  useEffect(() => {
    setContent();
    const categoryContent = firebase.database().ref(contentType);
    categoryContent.on('value', (snapshot) => {
      const previousList = snapshot.val();
      const list = [];
      for (const id in previousList) {
        if (previousList[id]?.activated) list.push({ id, ...previousList[id] });
      }
      list.sort((a, b) => new Date(b.displayDate) - new Date(a.displayDate));
      setContent(list);
    });
  }, [contentType]);

  useEffect(() => {
    setIntroText();
    if (contentType !== 'news') {
      firebase
        .database()
        .ref(contentType)
        .child('intro-text')
        .on('value', (snapshot) => setIntroText(snapshot.val()));
    } else {
      setIntroText('');
    }
  }, [contentType]);

  const convertTitles = (title) => {
    switch (title) {
      case 'press-media':
        return 'press / media';
      case 'about-us':
        return 'Bio';
      case 'shop':
        return 'shops';
      default:
        return title;
    }
  };

  // eslint-disable-next-line consistent-return
  const mapToCatergorie = (contentTypeToMap) => {
    if (content && content.length) {
      if (contentTypeToMap === 'creations') {
        return content.map((el) => (
          <Creation
            key={el.id}
            link={el.id}
            image={el.coverImg}
            title={el.title}
          />
        ));
      }
      if (contentTypeToMap === 'stylisme') {
        return content.map((el) => (
          <Stylisme
            key={el.id}
            link={el.id}
            image={el.coverImg}
            title={el.title}
          />
        ));
      }
      if (contentTypeToMap === 'news') {
        return content.map((el, i) => (
          <News
            key={el.id}
            date={el.displayDate}
            link={el.id}
            image={el.coverImg}
            title={el.title}
            text={el.description}
            position={i % 2 === 0 ? 'left' : 'right'}
            type={contentType}
            showSeparation={i !== content.length - 1}
          />
        ));
      }
      if (contentTypeToMap === 'press-media') {
        return content.map((el) => (
          <PressMedia
            key={el.id}
            link={el.id}
            image={el.coverImg}
            title={el.title}
          />
        ));
      }
      if (contentTypeToMap === 'shop') {
        return content.map((el, i) => (
          <Shop
            key={el.id}
            title={el.title}
            text={el.text}
            vitrine={el.vitrine}
            localisation={el.localisation}
            mapLink={el.mapLink}
            showSeparation={i !== content.length - 1}
            index={i}
          />
        ));
      }
      if (contentTypeToMap === 'about-us') {
        return content.map((el, i) => (
          <AboutUs
            key={el.id}
            title={el.title}
            text={el.text}
            image={el['about-us-img']}
            showSeparation={i !== content.length - 1}
          />
        ));
      }
      if (contentTypeToMap === 'contact') {
        return content.map((el) => (
          <Contact key={el.id} email={el.email} instagram={el.instagram} />
        ));
      }
    } else {
      return <CircularProgress />;
    }
  };

  return (
    <SectionContainer title={convertTitles(contentType)}>
      <ReactMarkdown remarkPlugins={[[gfm, { singleTilde: false }]]}>
        {introText}
      </ReactMarkdown>
      <Container
        $type={contentType}
        className={`${
          contentType === 'press-media' ? 'creations' : contentType
        }-main`}
      >
        <Fade delay={120} duration={1500}>
          {mapToCatergorie(contentType)}
        </Fade>
      </Container>
    </SectionContainer>
  );
};

export default MainCategories;
