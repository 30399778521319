/* eslint-disable object-curly-newline */
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

// import colors from '../../utils/colors';

import { useEffect, useState } from 'react';
import Image from '../../components/Image';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 5rem;
  width: 70vw;
  margin: 100px 0 20px;
  min-height: 80vh;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
    margin: 70px 0 20px;
    width: 100%;
  }
  @media screen and (max-width: 520px) {
    margin-top: 10px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: var(--h2-font-size);

  @media screen and (max-width: 500px) {
    font-size: var(--h3-font-size);
  }
`;

const ImageContainer = styled.div`
  display: flex;
  column-gap: 5rem;
`;

const ImageWrapper = styled.div`
  max-width: 30rem;
  max-height: 30rem;
  width: 35vw;
  height: 35vw;
  overflow: hidden;
  @media screen and (max-width: 1200px) {
    width: 50vw;
    height: 50vw;
  }
  @media screen and (max-width: 520px) {
    width: 60vw;
    height: 60vw;
  }
`;

const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
`;

const Separation = styled.div`
  display: ${({ showSeparation }) => (showSeparation ? 'block' : 'none')};
  height: 2px;
  width: 70vw;
  margin: auto;
  background-color: var(--text-color-dark);
  /* transition: background-color 180ms linear; */ /*Only for Themed animation (not implemented yep) */
`;

const AboutUs = ({ title, text, image, showSeparation }) => {
  const [profilImage, setProfilImage] = useState();

  useEffect(() => {
    if (image) {
      setProfilImage(Object.values(image)[0]);
    }
  }, [image]);

  return (
    <Container>
      <ImageContainer>
        <ImageWrapper>
          {image && <StyledImage src={profilImage} />}
        </ImageWrapper>
      </ImageContainer>
      <TextWrapper>
        <Title>{title}</Title>
        <ReactMarkdown remarkPlugins={[[gfm, { singleTilde: false }]]}>
          {text}
        </ReactMarkdown>
      </TextWrapper>
      <Separation showSeparation={showSeparation} />
    </Container>
  );
};

AboutUs.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  showSeparation: PropTypes.bool.isRequired,
};

export default AboutUs;
