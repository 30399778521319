// Import styles

import './App.scss';
// Import Swiper styles
import 'swiper/swiper-bundle.css';

// Import context
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import MyThmeProvider from './contexts/ThemeContext';
import WindowWidthProvider from './contexts/WindowWidthContext';
import AuthProvider from './contexts/AuthContext';
// Import components
import MainRouter from './routers/MainRouter';

function App() {
  const theme = createTheme({
    palette: {
      type: 'dark',
    },
  });
  return (
    <WindowWidthProvider>
      <MyThmeProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ThemeProvider theme={theme}>
            <AuthProvider>
              <MainRouter />
            </AuthProvider>
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </MyThmeProvider>
    </WindowWidthProvider>
  );
}

export default App;
