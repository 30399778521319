/* eslint-disable newline-per-chained-call */
/* eslint-disable operator-linebreak */
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TextField, Button } from '@material-ui/core';
import MDEditor from '@uiw/react-md-editor';

import imageCompression from 'browser-image-compression';

import { useAlert } from 'react-alert';
import { useHistory, useParams } from 'react-router-dom';
import firebase from '../../../utils/firebase.config';

const StyledForm = styled.form`
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

const ImageUploadWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ImageUpload = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageInput = styled.input`
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  width: 10rem;
  height: 10rem;
  overflow: hidden;
`;

const ImagePreview = styled.img`
  width: 100%;
  height: auto;
`;

const CreateCreator = () => {
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [image, setImage] = useState([]);
  const [imagePreview, setImagePreview] = useState();
  const [currentImageId, setCurrentImageId] = useState();

  const alert = useAlert();
  const history = useHistory();

  const { bioId } = useParams();

  const isUpdate = Boolean(bioId);

  useEffect(() => {
    if (bioId) {
      firebase
        .database()
        .ref()
        .child('about-us')
        .child(bioId)
        .on('value', (contentSnapshot) => {
          const content = contentSnapshot.val();
          const snapImage = Object.values(content['about-us-img'])[0];
          const snapImageId = Object.keys(content['about-us-img'])[0];
          setTitle(content.title);
          setText(content.text);
          setImage(snapImage);
          setImagePreview(snapImage);
          setCurrentImageId(snapImageId);
        });
    }
  }, []);

  const isButtonDisable =
    (title && text && image && !title.length) || !text.length || !image.length;

  const handleAddImage = (e) => {
    const { files } = e.target;
    setImage(files);
    setImagePreview(URL.createObjectURL(files[0]));
  };

  const handleUploadImage = async (targetCreatorId) => {
    const timeStamp = Date.now();
    if (!image.length) {
      alert.show('Merci de selectionner une image de image');
    } else {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const imageSource = image[0];
        if (typeof imageSource !== 'string') {
          const compressedFile = await imageCompression(imageSource, options);
          firebase
            .storage()
            .ref()
            .child(`about-us-images/${timeStamp}-${imageSource.name}`)
            .put(compressedFile)
            .on(
              'state_changed',
              () => {},
              (err) => {
                // catches the errors
                alert.show(err);
              },
              () => {
                firebase
                  .storage()
                  .ref('about-us-images')
                  .child(`${timeStamp}-${imageSource.name}`)
                  .getDownloadURL()
                  .then((fireBaseUrl) => {
                    if (isUpdate) {
                      firebase
                        .database()
                        .ref()
                        .child('about-us')
                        .child(targetCreatorId)
                        .child('about-us-img')
                        .update({ [currentImageId]: fireBaseUrl });
                    } else {
                      firebase
                        .database()
                        .ref()
                        .child('about-us')
                        .child(targetCreatorId)
                        .child('about-us-img')
                        .push(fireBaseUrl);
                    }
                  });
              }
            );
        }
      } catch (error) {
        alert.error('Something went wrong');
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const currentTimestamp = new Date().toUTCString();

    if (isUpdate) {
      firebase.database().ref().child('about-us').child(bioId).update({
        title,
        text,
        updatedAt: currentTimestamp,
      });

      handleUploadImage(bioId);

      alert.show('Modification réussie');
      history.push('/admin/about-us');
    } else {
      const createdCreator = firebase.database().ref().child('about-us').push({
        title,
        text,
        activated: false,
        createdAt: currentTimestamp,
        updatedAt: currentTimestamp,
      });
      if (!image.length) {
        alert.show('Merci de selectionner un fichier');
      } else {
        handleUploadImage(createdCreator.key);
      }
      alert.show('Création réussie');
      history.push('/admin/about-us');
    }
  };

  return (
    <>
      <h1>Création de profil</h1>
      <StyledForm onSubmit={handleSubmit}>
        <TextField
          label="Nom"
          variant="filled"
          name="title"
          id="Titre"
          placeholder="Nom"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
        <MDEditor
          style={{ width: '80%' }}
          textareaProps={{ placeholder: 'Please enter Markdown text' }}
          value={text}
          onChange={setText}
        />
        <ImageUploadWrapper>
          <ImageUpload>
            <ImageWrapper>
              <ImagePreview src={imagePreview} alt="profil" />
            </ImageWrapper>
            <ImageInput
              type="file"
              name="image"
              accept="image/*"
              onChange={handleAddImage}
            />
          </ImageUpload>
        </ImageUploadWrapper>
        <Button type="submit" variant="outlined" disabled={isButtonDisable}>
          Valider
        </Button>
      </StyledForm>
    </>
  );
};

export default CreateCreator;
