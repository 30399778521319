import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [isSignedIn, updateSignedIn] = useState('');

  return (
    <AuthContext.Provider
      value={{
        isSignedIn,
        updateSignedIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
