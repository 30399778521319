/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable operator-linebreak */
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { TabContext } from '@mui/lab';
import { Tab, Tabs, Typography } from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';

import styled from 'styled-components';

import firebase from '../../../utils/firebase.config';

import SectionIntroText from '../../../components/admin/SectionIntroText';
import './AdminList.scss';
import ContentLine from './ContentLine';

const StyledContainer = styled.div`
  min-height: 100vh;
`;

const StyledTab = styled(Tab)`
  padding: 0 1rem;
`;

const AdminItemsList = () => {
  const [sectionDatas, setSectionDatas] = useState([]);
  const [selectedTab, setSelectedTab] = useState('1');

  const { contentType } = useParams();

  useEffect(() => {
    firebase
      .database()
      .ref()
      .child(contentType)
      .on('value', (contents) => {
        const previousList = contents.val();
        const list = [];

        for (const id in previousList) {
          if (id !== 'intro-text') {
            list.push({ id, ...previousList[id] });
          }
        }
        // Sort by display date
        // TODO: make dropdown to sort by displayDate or createdAt or updatedAt
        list.sort((a, b) => new Date(b.displayDate) - new Date(a.displayDate));
        setSectionDatas(list);
      });
  }, []);

  const handleChangeTab = (_, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <StyledContainer className="admin-list">
      <Typography variant="h2">Administration des {contentType}</Typography>

      <TabContext value={selectedTab}>
        <Tabs
          onChange={handleChangeTab}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          value={selectedTab}
        >
          <StyledTab label="Gestion du contenu" value="1" />
          <StyledTab label="Ajouter du contenu" value="2" />
          <StyledTab
            disabled={contentType === 'news'}
            label="Texte d'introduction"
            value="3"
          />
        </Tabs>

        <TabPanel value="1">
          <table className="admin-list__table">
            <thead>
              <tr>
                <th>Date de création</th>
                <th>Titre</th>
                <th>Date d&apos;affichage</th>
                <th>Status</th>
                <th>Changer status</th>
                <th>Supprimer</th>
              </tr>
            </thead>
            <tbody>
              {sectionDatas.length &&
                sectionDatas.map((data) => (
                  <ContentLine
                    {...data}
                    contentType={contentType}
                    key={data.id}
                  />
                ))}
            </tbody>
          </table>
        </TabPanel>
        <TabPanel value="2">
          <Link
            to={`/admin/${contentType}/create`}
            className="admin-list__create"
          >
            Ajouter du contenu
          </Link>
        </TabPanel>
        <TabPanel value="3">
          <SectionIntroText contentType={contentType} />
        </TabPanel>
      </TabContext>
    </StyledContainer>
  );
};

export default AdminItemsList;
