/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

// Import modules
import PropTypes from 'prop-types';

// Import styles
import './Burger.scss';

const Burger = ({ menuOpen, handleOpenMenu }) => (
  <div
    className={`burger-container${menuOpen ? '--open' : ''}`}
    onClick={handleOpenMenu}
  >
    <div className="burger-line" />
  </div>
);

Burger.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  handleOpenMenu: PropTypes.func.isRequired,
};

export default Burger;
