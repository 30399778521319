// Import modules
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useContext } from 'react';

// Import context
import { ThemeContext } from '../contexts/ThemeContext';
import ContentEditProvider from '../contexts/ContentEditContext';
import ScrollPositionProvider from '../contexts/ScrollPositionContext';
// Import components
import Home from '../views/home/Home';
import MainCategories from '../views/mainCategories/MainCategories';
import CreationDetails from '../components/contentDetails/ContentDetails';
import Navbar from '../components/Navbar/Navbar';
import AdminHome from '../views/admin/AdminHome';
import AdminItemsList from '../views/admin/contentList/AdminList';
import CreateContent from '../views/admin/createContent/CreateContent';
import UpdateContent from '../views/admin/updateContent/UpdateContent';
import CreateShop from '../views/admin/createContent/CreateShop';
import PrivateRoute from './PrivateRoutes';
import CreateCreator from '../views/admin/createContent/CreateCreator';

const MainRouter = () => {
  const { lightTheme } = useContext(ThemeContext);

  return (
    <Router>
      <div className={`App${lightTheme ? '' : '-dark'}`}>
        <ScrollPositionProvider>
          <Navbar />
        </ScrollPositionProvider>
        <ContentEditProvider>
          <Switch>
            <Route exact path="/admin" component={AdminHome} />
            <PrivateRoute
              exact
              path="/admin/:contentType"
              component={AdminItemsList}
            />
            <PrivateRoute path="/admin/shop/create" component={CreateShop} />
            <PrivateRoute
              path="/admin/about-us/create"
              component={CreateCreator}
            />
            <PrivateRoute
              exact
              path="/admin/:contentType/create"
              component={CreateContent}
            />
            <PrivateRoute
              exact
              path="/admin/update/shop/:shopId"
              component={CreateShop}
            />
            <PrivateRoute
              exact
              path="/admin/update/about-us/:bioId"
              component={CreateCreator}
            />
            <PrivateRoute
              exact
              path="/admin/update/:contentType/:id"
              component={UpdateContent}
            />
          </Switch>
        </ContentEditProvider>

        <Switch>
          <Route path="/nav/:contentType/:id" component={CreationDetails} />
          <Route exact path="/nav/:contentType" component={MainCategories} />
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default MainRouter;
