/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isSignedIn } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isSignedIn) return <Component {...props} />;
        return (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        );
      }}
    />
  );
};
PrivateRoute.defaultProps = {
  location: null,
};

PrivateRoute.propTypes = {
  location: PropTypes.element,
  component: PropTypes.func.isRequired,
};

export default PrivateRoute;
