/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable object-curly-newline */

// Import modules
// import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import './ContentDetails.scss';

// Import utils

import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { Typography } from '@material-ui/core';
import firebase from '../../utils/firebase.config';
import ScrollToTop from '../../tools/ScrollToTop';

// Import components
import SwiperFade from '../SwiperFade';
import SectionContainer from '../SectionContainer';
import Image from '../Image';

const TextBox = styled.div`
  padding: 0 10px;
`;

const StyledImage = styled(Image)`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 90vh !important;
  max-width: 100vw;
  width: auto !important;
  padding-bottom: 0 !important;
  > img {
    max-width: 100vw;
  }
`;

const ContentDetails = ({ dataText, dataImage }) => {
  const [article, setArticle] = useState();
  const [images, setImages] = useState([]);
  const { contentType, id } = useParams();
  useEffect(() => {
    if (!dataText && !dataImage) {
      firebase
        .database()
        .ref()
        .child(contentType)
        .child(id)
        .on('value', (content) => setArticle(content.val()));
      firebase
        .database()
        .ref()
        .child(contentType)
        .child(id)
        .child('images')
        .on('value', (imagesList) => {
          if (imagesList?.val()) {
            // Make an array of all the firebase img object
            const tempImgArr = Object.values(imagesList?.val());
            // Set the state
            setImages(tempImgArr);
          }
        });
    }
  }, []);

  useEffect(() => {
    if (dataText) setArticle(dataText);
    if (dataImage) setImages(dataImage);
  }, [dataText, dataImage]);
  return (
    <SectionContainer title={article?.title} isDetailsPage>
      <ScrollToTop />
      <TextBox>
        <Typography variant="h5">{article?.subTitle}</Typography>
        <ReactMarkdown remarkPlugins={[[gfm, { singleTilde: false }]]}>
          {article?.text}
        </ReactMarkdown>
      </TextBox>
      {images.length ? (
        <SwiperFade multiple={images.length > 1}>
          {images.map((image) => (
            <SwiperSlide key={image}>
              <StyledImage src={image.path || image} alt={image.id} />
            </SwiperSlide>
          ))}
        </SwiperFade>
      ) : null}
      {/* {creationFind.details.videos ? (
        <ReactPlayer
          url={creationFind.details.videos[0]}
          width="100%"
          height="94vh"
          controls={false}
          loop
          playing
          playsinline
          muted
        />
      ) : null} */}
    </SectionContainer>
  );
};

ContentDetails.defaultProps = {
  dataText: null,
  dataImage: null,
};

ContentDetails.propTypes = {
  dataText: PropTypes.objectOf(PropTypes.any),
  dataImage: PropTypes.arrayOf(PropTypes.any),
};
export default ContentDetails;
