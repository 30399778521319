/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable prettier/prettier */

// Import modules
import PropTypes, { arrayOf } from 'prop-types';
import { Fade } from 'react-awesome-reveal';
import styled from 'styled-components';

const Container = styled.div`
  margin: 120px 0 20px;
  width: 87vw;
  min-height: calc(100vh - 140px);

  @media screen and (max-width: 520px) {
    margin: 60px 0 0;
    width: 93vw;
  }
  p {
    a {
      text-decoration: underline;
    }
  }
`;

const Title = styled.h1`
  user-select: none;
  font-size: var(--h1-font-size);
  text-transform: ${({ isDetailsPage }) =>
    isDetailsPage ? 'none' : 'uppercase'};
  color: ${({ isDetailsPage }) =>
    isDetailsPage ? 'var(--text-color-dark)' : 'var(--first-color-orange)'};

  @media screen and (max-width: 767px) {
    font-size: var(--h2-font-size);
  }

  @media screen and (max-width: 520px) {
    font-size: 15vw;
  }
`;

const SectionContainer = ({ children, title, isDetailsPage }) => (
  <Container>
    <Fade delay={120} duration={1500}>
      <Title isDetailsPage={isDetailsPage}>{title && title}</Title>
    </Fade>
    {children}
  </Container>
);

SectionContainer.defaultProps = {
  isDetailsPage: false,
  title: '',
};

SectionContainer.propTypes = {
  children: PropTypes.oneOfType([arrayOf(PropTypes.node), PropTypes.node])
    .isRequired,
  title: PropTypes.string,
  isDetailsPage: PropTypes.bool,
};

export default SectionContainer;
