// Import modules
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Import Contexts
import { Typography } from '@material-ui/core';
import { Card } from '@mui/material';

// Import Medias
import defaultImage from '../../assets/images/yperlab_logo_ff4612.png';
import colors from '../../utils/colors';
import Image from '../../components/Image';

const StyledImageWrapper = styled.div`
  width: 300px;
  height: 300px;
  max-width: 100%;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    width: 200px;
    height: 200px;
  }

  @media screen and (max-width: 520px) {
    width: 150px;
    height: 150px;
  }
`;

const StyledImage = styled(Image)`
  transition: all 120ms ease-in-out;
  max-width: 300px;

  @media screen and (max-width: 767px) {
    max-width: 200px;
  }

  @media screen and (max-width: 520px) {
    max-width: 150px;
  }
  > img {
    width: 100%;
  }
`;

const StyledCard = styled(Card)`
  margin: 4px;
  border: 1px solid ${colors.secondary.grey};
  max-width: 300px;

  @media screen and (max-width: 767px) {
    max-width: 200px;
  }

  @media screen and (max-width: 520px) {
    max-width: 150px;
  }
  &:hover ${StyledImage} {
    opacity: 0.8;
    transform: scale(1.01);
  }
`;

const StyledTypography = styled(Typography)`
  color: ${colors.text.white};
`;

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
`;

const Creation = ({ link, image, title }) => (
  <StyledCard>
    <StyledLink to={`/nav/creations/${link}`}>
      <StyledTypography variant="h6" align="center">
        {title}
      </StyledTypography>
    </StyledLink>
    <Link to={`/nav/creations/${link}`}>
      <StyledImageWrapper>
        <StyledImage src={image} alt={title} />
      </StyledImageWrapper>
    </Link>
  </StyledCard>
);

Creation.defaultProps = {
  image: defaultImage,
};

Creation.propTypes = {
  link: PropTypes.string.isRequired,
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Creation;
