/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import PropTypes from 'prop-types';

import defaultImage from '../assets/images/imagenotfound.jpg';

const Image = ({ alt, alreadyCentred, ...rest }) => {
  const [style, setStyle] = useState({});
  const handleSourceError = (e) => {
    e.target.src = defaultImage;
    e.target.alt = 'Not Found';
    if (!alreadyCentred) {
      setStyle({
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
      });
    } else {
      setStyle({
        width: '80%',
      });
    }
  };

  return <img alt={alt} {...rest} onError={handleSourceError} style={style} />;
};

Image.defaultProps = {
  alreadyCentred: false,
};

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  alreadyCentred: PropTypes.bool,
};

export default Image;
