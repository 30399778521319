/* eslint-disable object-curly-newline */
import { Switch } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import firebase from '../../../utils/firebase.config';

const ContentLine = ({
  contentType,
  id,
  title,
  displayDate,
  activated,
  createdAt,
}) => {
  const handleChange = () => {
    firebase
      .database()
      .ref()
      .child(contentType)
      .child(id)
      .update({ activated: !activated });
  };
  return (
    <tr key={id} className="admin-list__table__row">
      <td>{createdAt && new Date(createdAt).toLocaleString('fr-FR')}</td>
      <Link to={`/admin/update/${contentType}/${id}`}>
        <td className="admin-list__table__titles">{title}</td>
      </Link>
      <td>{new Date(displayDate).toLocaleDateString('fr-FR')}</td>
      <td>{activated ? 'actif' : 'inactif'}</td>
      <td>
        <Switch checked={activated} onChange={handleChange} />
      </td>
    </tr>
  );
};

ContentLine.defaultProps = {
  activated: false,
  createdAt: undefined,
};

ContentLine.propTypes = {
  contentType: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  displayDate: PropTypes.string.isRequired,
  activated: PropTypes.bool,
  createdAt: PropTypes.string,
};

export default ContentLine;
