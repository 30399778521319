/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable object-curly-newline */
// Import modules
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Import Contexts
import { motion } from 'framer-motion';
import { Typography } from '@material-ui/core';

// Import Medias
import defaultImage from '../../assets/images/yperlab_logo_ff4612.png';
import Image from '../../components/Image';
import SeeMoreIcon from '../../components/SeeMoreButton';

const Container = styled.div`
  display: flex;
  width: 70vw;
  justify-content: space-between;
  margin: 3rem 0 3rem;
  min-height: 80vh;
  flex-direction: ${({ position }) =>
    position === 'left' ? 'row' : 'row-reverse'};

  @media screen and (max-width: 1200px) {
    margin: 2rem 0 2rem;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  @media screen and (max-width: 500px) {
    min-height: fit-content;
    max-width: 100vw;
  }
`;

const Title = styled.h1`
  font-size: var(--h2-font-size);

  @media screen and (max-width: 500px) {
    font-size: var(--h3-font-size);
  }
`;

const ImageWrapper = styled.div`
  width: 50%;
  max-height: 80vh;
  overflow: hidden;
  ${({ position }) => position === 'right' && 'text-align: end;'}

  @media screen and (max-width: 1200px) {
    width: fit-content;
    max-height: none;
    max-width: 100vw;
    margin-top: 50px;
  }
`;

const ImageLink = styled(Link)`
  height: fit-content;
  width: fit-content;
`;

const StyledImage = styled(Image)`
  height: 100%;
  width: auto;
  transition: all 120ms ease-in-out;
  &:hover {
    opacity: 0.8;
    transform: scale(1.01) !important;
  }

  @media screen and (max-width: 500px) {
    max-width: 100vw;
    width: 100%;
    height: auto;
  }
`;

const TextBlock = styled.div`
  padding: 20px;
  width: 49%;
  position: relative;

  @media screen and (max-width: 1200px) {
    width: 100%;
    padding: 0;
  }

  @media screen and (max-width: 500px) {
    padding: 0;
  }
`;

const Separation = styled.div`
  display: ${({ showSeparation }) => (showSeparation ? 'block' : 'none')};
  height: 2px;
  width: 70vw;
  margin: auto;
  background-color: var(--text-color-dark);
  /* transition: background-color 180ms linear; */ /*Only for Themed animation (not implemented yep) */
`;

const News = ({
  date,
  link,
  image,
  title,
  text,
  position,
  type,
  showSeparation,
}) => (
  <motion.div exit={{ opacity: 0 }}>
    <Container position={position}>
      <ImageWrapper position={position}>
        <ImageLink to={`/nav/${type}/${link}`}>
          <StyledImage src={image} alt={title} height="80vh" />
        </ImageLink>
      </ImageWrapper>
      <TextBlock>
        <Typography>{new Date(date).toLocaleDateString('fr-FR')}</Typography>
        <Link to={`/nav/${type}/${link}`}>
          <Title>{title}</Title>
        </Link>
        {text && (
          <ReactMarkdown remarkPlugins={[[gfm, { singleTilde: false }]]}>
            {text}
          </ReactMarkdown>
        )}
        <SeeMoreIcon link={`/nav/${type}/${link}`} />
      </TextBlock>
    </Container>
    <Separation showSeparation={showSeparation} />
  </motion.div>
);
News.defaultProps = {
  image: defaultImage,
  position: 'left',
  text: '',
};

News.propTypes = {
  date: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  position: PropTypes.string,
  type: PropTypes.string.isRequired,
  showSeparation: PropTypes.bool.isRequired,
};

export default News;
