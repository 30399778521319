/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-underscore-dangle */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-await-in-loop */
/* eslint-disable operator-linebreak */
/* eslint-disable no-loop-func */
/* eslint-disable newline-per-chained-call */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './CreateContent.scss';
import { useAlert } from 'react-alert';
import MDEditor from '@uiw/react-md-editor';

import {
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
} from '@material-ui/core';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';

import { DatePicker } from '@material-ui/pickers';

import imageCompression from 'browser-image-compression';

import styled from 'styled-components';

import { Button, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import firebase from '../../../utils/firebase.config';
import ContentDetails from '../../../components/contentDetails/ContentDetails';

const StyledImageList = styled.div`
  width: 80vw;
  height: 15rem;
  overflow: auto;
  border: 1px solid var(--first-color-orange);
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
`;

const StyledImageUploadBox = styled.div`
  border: 1px solid var(--first-color-orange);
  border-radius: 5px;
  margin: 1rem;
  padding: 1rem;
`;

const CreateContent = () => {
  const [step, setStep] = useState(1);
  const [content, setContent] = useState({});
  const [desc, setDesc] = useState();
  const [text, setText] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date().toDateString());
  const [key, setKey] = useState();
  const [imageAsUrl, setImageAsUrl] = useState([]);
  const [checkedRadio, setCheckedRadio] = useState();
  const [coverTab, setCoverTab] = useState(0);

  const [coverImagesUrlList, setCoverImagesUrlList] = useState([]);

  const { contentType } = useParams();
  const alert = useAlert();
  const history = useHistory();

  // Get the cover images list
  useEffect(() => {
    firebase
      .database()
      .ref()
      .child('cover-images')
      .on('value', (imagesList) => {
        const prevList = imagesList.val();
        const list = [];
        for (const imgId in prevList) {
          list.push({ imgId, imgUrl: prevList[imgId] });
        }
        const urlList = list.map((coverImage) => coverImage.imgUrl);
        setCoverImagesUrlList(urlList);
      });
  }, []);

  const handleChangeDate = (date) => {
    setSelectedDate(date._d.toDateString());
  };

  const handleSubmitDescription = async (e) => {
    e.preventDefault();
    const createdAt = new Date().toUTCString();
    const tempContent = {
      ...content,
      displayDate: selectedDate,
      description: contentType !== 'creations' ? desc : null,
      text,
      activated: false,
    };
    setContent(tempContent);
    if (!tempContent.description && !tempContent.text) {
      alert.show('Merci de remplir au moins un champ MarkDown');
      return;
    }
    setStep(2);
    const tempKey = firebase
      .database()
      .ref()
      .child(contentType)
      .push({
        ...content,
        displayDate: selectedDate,
        description: contentType === 'news' ? desc : null,
        text,
        activated: false,
        createdAt,
      }).key;
    setKey(tempKey);
  };
  const handleChange = (e) => {
    setContent({ ...content, [e.target.name]: e.target.value });
  };

  const [image, setImage] = useState([]);

  const [imagesPreviews, setImagesPreviews] = useState([]);

  const handleAddImage = (e) => {
    const { files } = e.target;
    setImage(files);
    const urlArr = [];
    for (let i = 0; i < files.length; i += 1) {
      urlArr.push(URL.createObjectURL(files[i]));
    }
    setImagesPreviews(urlArr);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    const timeStamp = Date.now();
    const tempUrlArr = [];
    if (!image.length) alert.show('Merci de selectionner un fichier');
    else {
      for (let i = 0; i < image.length; i += 1) {
        const imageFile = image[i];
        const options = {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        try {
          const compressedFile = await imageCompression(imageFile, options);
          firebase
            .storage()
            .ref()
            .child(`images/${timeStamp}-${image[i].name}`)
            .put(compressedFile)
            .on(
              'state_changed',
              () => {},
              (err) => {
                // catches the errors
                alert.show(err);
              },
              () => {
                firebase
                  .storage()
                  .ref('images')
                  .child(`${timeStamp}-${image[i].name}`)
                  .getDownloadURL()
                  .then((fireBaseUrl) => {
                    tempUrlArr.push(fireBaseUrl);
                    setImageAsUrl((prev) => [...prev, fireBaseUrl]);
                    firebase
                      .database()
                      .ref()
                      .child(contentType)
                      .child(key)
                      .child('images')
                      .push(fireBaseUrl);
                  });
              }
            );
        } catch (error) {
          alert.error('Something went wrong');
        }
      }
      setTimeout(() => {
        setStep(3);
      }, 1200);
    }
  };

  const handleUploadCoverImage = async (e) => {
    e.preventDefault();
    const timeStamp = Date.now();
    if (!image.length) alert.show('Merci de selectionner un fichier');
    else {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(image[0], options);
        firebase
          .storage()
          .ref()
          .child(`cover-images/${timeStamp}-${image[0].name}`)
          .put(compressedFile)
          .on(
            'state_changed',
            () => {},
            (err) => {
              // catches the errors
              alert.show(err);
            },
            () => {
              firebase
                .storage()
                .ref('cover-images')
                .child(`${timeStamp}-${image[0].name}`)
                .getDownloadURL()
                .then((fireBaseUrl) => {
                  setCoverImagesUrlList((prev) => [...prev, fireBaseUrl]);
                  firebase
                    .database()
                    .ref()
                    .child('cover-images')
                    .push(fireBaseUrl);
                });
            }
          );
      } catch (error) {
        alert.error('Something went wrong');
      }
    }
  };

  const handleRadioChange = (e) => {
    setCheckedRadio(e.target.value);
  };

  const handleChangeTab = (e, newValue) => {
    setCoverTab(newValue);
  };

  const handleFinalSubmit = (e) => {
    e.preventDefault();
    if (!checkedRadio) {
      alert.error('Merci de sélectionner une image');
    } else {
      firebase
        .database()
        .ref()
        .child(contentType)
        .child(key)
        .child('coverImg')
        .set(checkedRadio);
      alert.show('Création réussie');
      history.push(`/admin/${contentType}`);
    }
  };

  return (
    <div className="create-content">
      <h3>Etape {step}/3</h3>
      <h1>Ici on creer des {contentType}</h1>
      {step === 1 ? (
        <form
          className="create-content__form"
          onSubmit={handleSubmitDescription}
        >
          <TextField
            label="Titre"
            variant="filled"
            name="title"
            id="Titre"
            placeholder="Titre"
            value={content.title}
            onChange={handleChange}
            required
          />

          <TextField
            label="Sous-titre"
            variant="filled"
            name="subTitle"
            id="subTitle"
            placeholder="Sous-titre"
            value={content.subTitle}
            onChange={handleChange}
          />
          {contentType === 'news' && (
            <div>
              Texte d&apos;introduction (page rubrique)
              <MDEditor
                textareaProps={{ placeholder: 'Please enter Markdown text' }}
                value={desc}
                onChange={setDesc}
              />
            </div>
          )}

          <div>
            Texte de la page de détail
            <MDEditor value={text} onChange={setText} />
          </div>

          <DatePicker
            margin="normal"
            label="date"
            id="date-picker-dialog"
            InputLabelProps={{
              shrink: true,
            }}
            value={selectedDate}
            onChange={handleChangeDate}
            required
          />
          <Button type="submit" variant="contained" color="secondary">
            Etape suivante <ArrowForwardIosIcon />
          </Button>
        </form>
      ) : null}
      {step === 2 ? (
        <>
          <div className="prev-images">
            <h3>-- Images du carousel --</h3>
            <div className="prev-images__image-block">
              {imagesPreviews.length
                ? imagesPreviews.map((img) => (
                    <div className="prev-images__container">
                      <img
                        className="prev-images__details"
                        src={img}
                        alt="precedente"
                      />
                    </div>
                  ))
                : null}
            </div>
          </div>
          <StyledImageUploadBox>
            <Typography>Ajouter d&apos;images</Typography>
            <form onSubmit={handleUpload}>
              <input
                type="file"
                name="images"
                multiple
                accept="image/*"
                onChange={handleAddImage}
              />
              <input type="submit" value="Upload des images" />
            </form>
          </StyledImageUploadBox>
        </>
      ) : null}
      {step === 3 ? (
        <>
          <Typography>Image principale</Typography>
          {coverTab === 1 && (
            <StyledImageUploadBox>
              <div>Ajouter une image</div>
              <form onSubmit={handleUploadCoverImage}>
                <input
                  type="file"
                  name="images"
                  accept="image/*"
                  onChange={handleAddImage}
                />
                <input type="submit" value="Upload de l'image" />
              </form>
            </StyledImageUploadBox>
          )}
          <form onSubmit={handleFinalSubmit}>
            <TabContext value={coverTab}>
              <Tabs onChange={handleChangeTab}>
                <Tab label="Image de l'article" />
                <Tab label="Image unique" />
              </Tabs>
              <RadioGroup
                aria-label="Image principale"
                onChange={handleRadioChange}
                defaultChecked={checkedRadio ?? ''}
                defaultValue={checkedRadio ?? ''}
              >
                <TabPanel value={0}>
                  <StyledImageList>
                    {imageAsUrl.length &&
                      imageAsUrl.map((imgUrl) => (
                        <>
                          <FormControlLabel
                            style={{
                              flexDirection: 'column-reverse',
                              margin: '10px',
                            }}
                            value={imgUrl}
                            control={<Radio />}
                            label={
                              <img
                                src={imgUrl}
                                alt=""
                                style={{ width: '75px' }}
                              />
                            }
                          />
                        </>
                      ))}
                  </StyledImageList>
                </TabPanel>
                <TabPanel value={1}>
                  <StyledImageList>
                    {Boolean(coverImagesUrlList.length) &&
                      coverImagesUrlList.map((coverImg) => (
                        <>
                          <FormControlLabel
                            style={{
                              flexDirection: 'column-reverse',
                              margin: '10px',
                            }}
                            value={coverImg}
                            control={<Radio />}
                            label={
                              <img
                                src={coverImg}
                                alt=""
                                style={{ width: '75px' }}
                              />
                            }
                          />
                        </>
                      ))}
                  </StyledImageList>
                </TabPanel>
              </RadioGroup>
            </TabContext>
            <Button type="submit" variant="contained" color="secondary">
              Valider <CheckCircleOutlineIcon />
            </Button>
          </form>
        </>
      ) : null}
      <p>------------ APERCU ------------</p>
      <ContentDetails
        dataText={content}
        dataImage={imagesPreviews || imageAsUrl}
      />
    </div>
  );
};

export default CreateContent;
