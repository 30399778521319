// Import modules
import PropTypes from 'prop-types';
import { Swiper } from 'swiper/react';
import styled from 'styled-components';

// import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
  Autoplay,
  Lazy,
} from 'swiper';

SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
  Autoplay,
  Lazy,
]);

const StyledSwiper = styled(Swiper)`
  height: 90vh;

  .swiper-pagination-bullet {
    border: 2px solid var(--first-color-orange);
    background: none;
    opacity: 0.4;
    &-active {
      opacity: 0.9;
      background: var(--first-color-orange);
    }
  }

  .swiper-container {
    max-width: 70vw;
    margin: 20px auto;
    overflow: visible;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-scrollbar {
    display: none;
  }

  @media (max-width: 520px) {
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
  }
`;

const Container = styled.div`
  height: 80vh;
  background-color: aliceblue;
`;

const SwiperFade = ({ children, multiple }) => (
  <StyledSwiper
    effect="fade"
    fadeEffect={{ crossFade: true }}
    autoplay
    speed={800}
    slidesPerView={1}
    navigation={multiple}
    pagination={multiple && { clickable: true }}
    scrollbar={{ draggable: true }}
    preloadImages={false}
    lazy
  >
    <Container>{children}</Container>
  </StyledSwiper>
);

SwiperFade.defaultProps = {
  multiple: true,
};

SwiperFade.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  multiple: PropTypes.bool,
};

export default SwiperFade;
