// Import modules
import { useContext, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { Fade } from 'react-awesome-reveal';

import styled from 'styled-components';

// Import medias
import mobileVideo from '../../assets/videos/frontpage_vertical.m4v';
import desktopVideo from '../../assets/videos/homepage_desktop.m4v';

// Import styles
import './Video.scss';

// Import contexts
import { WindowWidthContext } from '../../contexts/WindowWidthContext';

const StyledPlayer = styled(ReactPlayer)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Video = () => {
  const windowSize = useContext(WindowWidthContext);

  const [videoSrc, setVideoSrc] = useState('');
  useEffect(() => {
    setVideoSrc(windowSize < 520 ? mobileVideo : desktopVideo);
  }, [windowSize]);
  return (
    <Fade className="video-fade">
      <StyledPlayer
        className="video-intro"
        url={videoSrc}
        controls={false}
        width="100%"
        height="auto"
        loop
        playing
        playsinline
        muted
      />
    </Fade>
  );
};

export default Video;
